module.exports = new Promise((resolve) => {
    // Название host приложения
    const localAppName = 'admin_host';
    // Название получаемого приложения
    const remoteName = 'admin_section_promocodes';

    // Название host приложения
    const mode = 'production';

    // Ссылка на точку входа в получаемое приложение
    let remoteUrl;

    // Установка название host приложения
    if (!window.appName) window.appName = localAppName;
    if (remoteName == window.appName) {
      remoteUrl = '/remoteEntry.js';
    }

    // Инит скрипта
    const init = () => {
      // Тег скрипта с ссылкой на remote приложение
      let script;

      // Проверяем если ри уже скрипт с ссылкой src == remoteUrl
      const headChildren = document.head.children;
      for (let headChild of headChildren) {
        if (headChild.src == remoteUrl) {
          script = headChild;
          break;
        }
      }

      // Если не нашли скрипт, то создаем
      if (!script) {
        script = document.createElement('script');
        script.src = remoteUrl;
        document.head.appendChild(script);
      }

      // Прокси который необходимо вернуть, когда подключится скрипт
      const proxy = {
        get: (request) => {
          return window[remoteName].get(request);
        },
        init: (arg) => {
          try {
            return window[remoteName].init(arg);
          } catch (e) {
            console.log('remote container already initialized');
          }
        },
      };

      const resolveProxy = () => {
        if (remoteName == window.appName) resolve();
        script.readyState = 'complete';
        resolve(proxy);
      };

      // Проверка сработал ли скрипт
      if (script.readyState === 'complete') {
        resolveProxy();
      } else {
        script.addEventListener('load', resolveProxy);
      }
    };

    // Если имя текущего хост приложения совпадает с remote приложением
    if (remoteUrl) {
      init();
    }
    // Получение json с конфигурацией remotes
    else {
      const initWithExternalRemoteUrl = () => {
        window.appWebSettings.then((res) => {
          const appWebSettingsRemotes = res[mode].remotes;
          // Ссылка на получаемое приложение
          const url = appWebSettingsRemotes[remoteName];
          // Ссылка на точку входа в получаемое приложение
          remoteUrl = `${url}/remoteEntry.js`;
          init();
        });
      };

      if (window.appWebSettings) initWithExternalRemoteUrl();
      else {
        window.appWebSettings = fetch('/appWebSettings.json')
          .then(async (appWebSettingsData) => {
            // Чтение текста json
            return await appWebSettingsData.text().then((appWebSettingsStr) => {
              const appWebSettings = JSON.parse(appWebSettingsStr);
              return appWebSettings;
            });
          })
          .catch(() => {
            console.error('Добавьте appWebSettings.json файл в public папку');
          });
        initWithExternalRemoteUrl();
      }
    }
  });;